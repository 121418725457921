import classNames from "classnames";

const FasciaSponsor = ({ data, className }) => {
  return (
    <div className={classNames("sponsor", className)}>
      {data?.tipologiaSponsor && <h2>{data.tipologiaSponsor}</h2>}
      <div className="row">
        {data?.sponsorCollection?.items?.map((item) => (
          <div
            key={item.sys.id}
            className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center"
          >
            <div className="sponsor__logo">
              <a
                title={item.title}
                target="_blank"
                rel="noopener noreferrer"
                href={item.description}
              >
                <img alt={item.title} src={item.url} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FasciaSponsor;
