import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";
import { useNavigazione } from "../context/navigazione";

const addClass = (children = []) => {
  // flatMap returns a flattened array - very useful
  const mappedChildren = children.flatMap((child) => {
    if (typeof child === "string") {
      // the regex that handles parsing the actual string and extracting the text
      const matches = child.match(/\((.+)\)(?=\[\/(\w+)\])/);
      if (matches) {
        return createSpanFromMatches(matches, child);
      }
    }
    if (typeof child === "object") {
      const content = child.props?.children;
      const className = child.props?.className;
      const matches =
        typeof content === "string" && content.match(/\((.+)\)(?=\[\/(\w+)\])/);

      if (matches) {
        return createSpanFromMatches(matches, content, { className });
      }
    }
    // make sure to always return the content if there is no match to the regex
    return child;
  });
  return mappedChildren;
};
const createSpanFromMatches = (matches, text, restProps = {}) => {
  const content = text.split(`${matches[0]}[/${matches[2]}]`);
  return [...new Set(content)] // get the unique values / avoid ["", ""] - when there are no other parts of text
    .map((text) =>
      text === "" ? ( // map over the unique values to replace that which was split
        <span className={matches[2]}>{matches[1]}</span> // return the element with the colour
      ) : (
        text
      )
    ); // or return the text
};

const renderOptions = (links, getLink) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{addClass(children)}</p>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="" href={node.data?.uri}>
          {children}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const { id } = node.data.target.sys;
      const hyperlink = links.entries?.hyperlink?.find((i) => i.sys.id === id);
      return hyperlink ? <Link to={getLink(hyperlink)}>{children}</Link> : null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { id } = node.data.target.sys;
      const block = links.entries.block.find((i) => i.sys.id === id);
      return block ? (
        <div dangerouslySetInnerHTML={{ __html: block.html }} />
      ) : null;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { id } = node.data.target.sys;
      const inline = links.assets.hyperlink.find((i) => i.sys.id === id);
      return inline ? (
        <a download={inline.title} href={inline.url}>
          {children}
        </a>
      ) : null;
    },
  },
});

const ContentfulRichText = ({ data, id, rientro }) => {
  const { getLink } = useNavigazione();
  return (
    <div className="row">
      <div className={`${rientro ? "col-12 offset-md-2 col-md-8 " : "col-12"}`}>
        {documentToReactComponents(
          data.json,
          renderOptions(data.links, getLink)
        )}
      </div>
    </div>
  );
};

export default ContentfulRichText;
