import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../context/user";
import { useNavigazione } from "../context/navigazione";

const PrivateRoute = ({ component: Component }) => {
  const { isLogged } = useContext(UserContext);
  const { getPathByTemplate } = useNavigazione();
  if (!isLogged) {
    const areaClientiLink = getPathByTemplate("areaClienti");
    return isLogged === false ? <Navigate to={areaClientiLink || "/"} /> : null;
  }
  return <>{Component}</>;
};

export default PrivateRoute;
