import Header from "../components/header";
import Breadcrumbs from "../components/breadcrumbs";
import { usePage } from "../hooks/useQueryCustom";
import Seo from "../components/seo";
import ContentfulRichText from "../components/contentful-rich-text";
import FasciaArticoli from "../components/fascia-articoli";

const BigliettiPrenotazioni = ({ page, breadcrumbs }) => {
  const { data } = usePage(page.link.slice(1));
  // console.log("AreaClienti page: ", data);

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper mt-3 mt-lg-5" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{data?.titoloInPagina || data?.titolo}</h1>
            </div>
            {data && (
              <div className="col-12 col-lg-9 offset-lg-3">
                <ContentfulRichText data={data.testo} />
                {data.articoliCollection.items
                  .filter((item) => item.layout === "Link due righe")
                  ?.map((fascia, index) => (
                    <FasciaArticoli
                      key={index}
                      id={fascia.sys.id}
                      rientro={fascia.rientro}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BigliettiPrenotazioni;
