import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = ({
  title = "Festival della Scienza",
  description = "Festival della Scienza",
  robots,
  sitename = true,
}) => (
  <Helmet>
    <title>
      {title} {sitename ? " | Festival della Scienza" : ""}
    </title>
    {description && <meta name="description" content={description} />}
    {robots && <meta name="robots" content={robots} />}
  </Helmet>
);

export default Seo;
