import Modal from "react-overlays/Modal";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/it";
import Icon from "../components/icons";
import { useAddBooking } from "../hooks/useQueryCart";
import UserContext from "../context/user";

const renderBackdrop = () => <div className="modal__external"></div>;

const BookingModal = ({ show, daycode, slot, premium, hide, quantity }) => {
  const day = dayjs(daycode, "YYYYMMDD").locale("it");
  const npart = Array.from(Array(quantity).keys());
  const { userProfile } = useContext(UserContext);
  const addBooking = useAddBooking();

  const {
    register,
    handleSubmit,
    // reset,
    // formState: { errors, isValid, isSubmitted, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      partecipanti: premium
        ? [
            {
              nome: userProfile.attributes?.name,
              cognome: userProfile.attributes?.family_name,
              telefono: userProfile.attributes?.phone_number,
            },
          ]
        : null,
    },
  });

  const onSubmit = (data) => {
    //console.log("addBook", data, slotSelected);
    const payload = {
      id_slot: slot.id_slot,
      quant: Number(quantity),
      ...data,
    };
    try {
      console.log("payload", payload);
      addBooking.mutate(payload);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const onClose = () => {
    addBooking.reset();
    hide();
  };

  return (
    <Modal
      className="modal__internal d-flex flex-column"
      show={show}
      renderBackdrop={renderBackdrop}
    >
      <>
        <div className="modal__header">
          <h3>Partecipanti</h3>
        </div>
        <div className="d-flex flex-column flex-fill modal__body  min-h-0">
          {/*  <p>
            Ti chiediamo di inserire i tuoi dati (nome, cognome, telefono) per
            avvertiti in caso di variazioni relative all'evento prenotato. I
            dati verranno cancellati dai nostri sistemi al termine della
            manifestazione.
          </p> */}
          <p>
            Giorno della prenotazione:{" "}
            <strong>{day.format("dddd D MMMM")}</strong>
            <br />
            Orario: <strong>{slot.orario}</strong>
          </p>
          <form
            name="prenota"
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex flex-column flex-fill my-3 min-h-0"
          >
            <div className="flex-fill form modal__form modal__form--scrollable min-h-0">
              {npart.map((item, index) => (
                <div key={index} className="mb-3">
                  <div className="mb-2">
                    <strong>Partecipante {index + 1}</strong>
                  </div>
                  <div className="d-flex flex-wrap gap-3">
                    <input
                      className="form__input"
                      placeholder="Nome"
                      type="text"
                      {...register(`partecipanti.${index}.nome`, {
                        required: true,
                      })}
                      readOnly={premium}
                    />
                    <input
                      className="form__input"
                      placeholder="Cognome"
                      type="text"
                      {...register(`partecipanti.${index}.cognome`, {
                        required: true,
                      })}
                      readOnly={premium}
                    />
                    <input
                      className="form__input"
                      placeholder="Telefono"
                      type="text"
                      {...register(`partecipanti.${index}.telefono`, {
                        required: true,
                      })}
                      readOnly={premium}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-between pt-3 gap-4">
              {addBooking.isSuccess ? (
                <div>
                  <Icon name="success" /> Prenotazione aggiunta
                </div>
              ) : (
                <button className="btn btn--link" type="submit">
                  Conferma
                </button>
              )}

              <button className="btn btn--link" type="button" onClick={onClose}>
                Chiudi
              </button>
            </div>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default BookingModal;
