import Icon from "../components/icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useNavigazione } from "../context/navigazione";

const SectionLink = ({ data, small }) => {
  const { getLink } = useNavigazione();

  return (
    <div className="container-md my-3 my-md-5">
      {data?.titoloSezione && (
        <div className="row">
          <div className="col-12">
            <h2 className="graphic">
              <span className="graphic__label">{data.titoloSezione}</span>
              <span className="graphic__line">&nbsp;</span>
            </h2>
          </div>
        </div>
      )}
      <div
        className={classNames(
          "section-link d-flex flex-wrap",
          small && "section-link--small",
          data?.articoliCollection?.items?.length > 2 &&
            "justyify-content-between"
        )}
      >
        {data.articoliCollection?.items?.filter(Boolean)?.map((item, index) => {
          const link = getLink(item);
          return (
            <Link
              key={index}
              className={classNames(
                "section-link__box",
                small && "section-link__box--small",
                data?.articoliCollection?.items?.length < 4 &&
                  "section-link__box--full"
              )}
              title={`${item.titoloInBoxLinea1} ${item.titoloInBoxLinea2}`}
              to={link}
            >
              <Icon name="angle-right" />
              <span className="small">{item.titoloInBoxLinea1}</span>
              {item.titoloInBoxLinea2}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SectionLink;
