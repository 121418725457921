import { useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Modal from "react-overlays/Modal";
import Header from "../components/header";
import Icon from "../components/icons";
import { useProtagonisti } from "../hooks/useQueryCustom";
import Breadcrumbs from "../components/breadcrumbs";
import Seo from "../components/seo";
import { useNavigazione } from "../context/navigazione";
import { stripHtml } from "../utils/utility";

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map((x) => String.fromCharCode(x));

const Protagonisti = ({ page, breadcrumbs }) => {
  const [showModal, setShowModal] = useState(false);
  const [relatore, setRelatore] = useState(null);
  const closeModal = useCallback(() => setShowModal(false), []);

  const [searchParams, setSearchParams] = useSearchParams();
  const letter = searchParams.get("letter") || "A";
  const { data: relatori } = useProtagonisti(letter);
  const { getPathByTemplate } = useNavigazione();
  const programmaPath = getPathByTemplate("programma");

  const openModal = (item) => {
    setRelatore(item);
    setShowModal(true);
  };

  const gotoLetter = (value) => {
    const params = new URLSearchParams(searchParams);
    params.set("letter", value);
    setSearchParams(params);
  };

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper mt-3 mt-lg-5" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>I protagonisti</h1>
            </div>
          </div>
          <div className="protagonisti">
            <div className="row">
              <div className="col-sm-12">
                <div className="my-4 text-center">
                  {alphabet.map((l) => (
                    <button
                      key={l}
                      className={classNames(
                        "protagonisti__letter",
                        l === letter && "protagonisti__letter--active"
                      )}
                      onClick={() => gotoLetter(l)}
                    >
                      {l}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {relatori && (
              <div className="row">
                {relatori.map((item, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4">
                    <div className="mb-4">
                      <h2
                        type="button"
                        className="protagonisti__name"
                        onClick={() => openModal(item)}
                      >
                        {item.cognome} {item.nome}
                      </h2>

                      <div className="protagonisti__desc">
                        {stripHtml(item?.biografia_catalogo)}
                      </div>
                      <Link
                        className="btn-arrow"
                        title="Eventi correlati"
                        to={`${programmaPath}?idrelatore_r=${item.idrelatore}`}
                        rel="nofollow"
                      >
                        <Icon name="angle-right"></Icon>
                        Eventi correlati
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {relatore && (
        <Modal
          show={showModal}
          onHide={closeModal}
          className="modal__internal d-flex flex-column"
          aria-labelledby="modal-label"
        >
          <>
            <button
              aria-label="close"
              className="modal__close"
              onClick={closeModal}
            >
              <Icon className="ms-2" name="xmark" />
            </button>
            <div className="modal__header">
              <h3>
                {relatore.nome} {relatore.cognome}
              </h3>
            </div>
            <div
              className="modal__body"
              dangerouslySetInnerHTML={{
                __html: relatore.biografia,
              }}
            ></div>
          </>
        </Modal>
      )}
    </>
  );
};
export default Protagonisti;
