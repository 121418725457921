import { useForm } from "react-hook-form";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import { usePremium } from "../hooks/useQueryCart";
import { usePage } from "../hooks/useQueryCustom";
import PageContent from "../components/page-content";

const Premium = ({ page, breadcrumbs }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      barcode: null,
    },
  });
  const { data } = usePage(page.link.slice(1));

  const addPremium = usePremium();
  console.log("errors", errors);
  const onSubmit = async (data) => {
    try {
      console.log("payload", data);
      const response = await addPremium.mutateAsync(data);
      console.log("response", response);
    } catch (err) {
      setError("root.serverError", {
        type: err?.code,
      });
      console.error("Error: ", err?.code);
    }
  };

  return (
    <>
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper" id="main">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h1>Attiva premium</h1>
            </div>
          </div>
          <div className="bg-gray px-4 py-5 mb-4">
            <div className="row">
              <div className="col-8 offset-md-2">
                <form
                  className="form"
                  name="premium"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <fieldset>
                    <PageContent data={data} />
                    <label className="form__label  mb-2">
                      <strong>Codice premium</strong>
                    </label>
                    <input
                      className="form__input"
                      type="text"
                      {...register("barcode", {
                        required: true,
                        pattern: /^[0-9]{13}$/,
                      })}
                    />
                    {errors.barcode?.type === "required" && (
                      <p role="alert" className="red my-2">
                        Valore mancante
                      </p>
                    )}
                    {errors.barcode?.type === "pattern" && (
                      <p role="alert" className="red my-2">
                        Bar code non valido
                      </p>
                    )}
                    {errors?.root?.serverError?.type === "invalid" && (
                      <p role="alert" className="red my-2">
                        Bar code non valido
                      </p>
                    )}
                    {errors?.root?.serverError?.type === "remote" && (
                      <p role="alert" className="red my-2">
                        Servizio non disponoibile
                      </p>
                    )}
                    <button className="btn btn--link mt-5 m-auto" type="submit">
                      Invia
                    </button>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Premium;
