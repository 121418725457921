import dayjs from "dayjs";
import "dayjs/locale/it";
import {
  FESTIVAL_DAY,
  TIPO_EVENTO,
  TIPO_CONFERENZA,
  TIPO_MOSTRA,
  TIPO_SPETTACOLO,
  DISCIPLINE,
  ID_CONFERENZA,
  ID_EVENTO_SPECIALE,
  ID_MOSTRA,
  ID_SPETTACOLO,
  ID_LABORATORIO,
  ID_EVENTO_OSPITE,
  ERRORS,
  PLANET_A,
  PLANET_B,
} from "./constants";
import { defaultLabels } from "./labels";

const IMG_ADMIN = import.meta.env.VITE_BO_ADMIN + "/fadmin";

export const stripHtml = (html) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const getLink = (item) => {
  if (item?.linkEsterno) return item.linkEsterno;
  if (item?.file?.url) return item.file.url;

  return item.__typename === "Articolo"
    ? `/news/${item.slug}/`
    : `/${item.slug}`;
};

export const getTarget = (data) => {
  try {
    let target = data?.target || "all";
    if (!target || target === "all") return "Per tutti";
    if (target === "20+") return "Dai 20 anni";
    if (target === "6-7,8-10,20+") return "da 6 a 10 anni e da 20 anni in su";
    const range_s = target.split(",");
    if (range_s.length > 1) {
      const lastrange = range_s.length - 1;
      if (range_s[lastrange].trim() === "20+") {
        return "Da " + range_s[0].split("-")[0] + " anni";
      } else if (range_s[lastrange].trim() === "all") {
        return "Per tutti";
      } else {
        target = "Da " + range_s[0].split("-")[0];
        return target
          .concat(" a ")
          .concat(range_s[lastrange].split("-")[1])
          .concat(" anni");
      }
    } else {
      const range = range_s[0].split("-");
      return "Da "
        .concat(range[0])
        .concat(" a ")
        .concat(range[1])
        .concat(" anni");
    }
  } catch (err) {
    console.log(err);
    return "all";
  }
};

export const getTipoEvento = (value) => {
  return TIPO_EVENTO.find((item) => item.value === value)?.label;
};
export const getDisciplina = (data) => {
  return DISCIPLINE.find((item) => item.value === data.disciplina)?.label;
};

export const isScuole = (data) => {
  return data?.sezione_fe === "2" ? true : false;
};

export const onlyOnline = (data) => {
  return data?.eventi_organizzazione.fruizione === "2" ? true : false;
};

export const isOnline = (data) => {
  return data?.eventi_organizzazione.fruizione === "3" ? true : false;
};

export const isConferenza = (data) => {
  return data?.idevento["@_idgenere"] === ID_CONFERENZA;
};
export const isSpettacolo = (data) => {
  return data?.idevento["@_idgenere"] === ID_SPETTACOLO;
};

export const isPrenotabile = (data) => {
  return (
    data?.eventi_organizzazione.prenotabile === "Online" ||
    data?.eventi_organizzazione.prenotabile === "CallCenter" ||
    data?.eventi_organizzazione.prenotabile === "Gratuita"
  );
};

export const getTipologiaScuole = (data) => {
  if (isDigitalk(data)) {
    return "Digitalk";
  } else {
    return getSubType(data);
  }
};

export const isDigitalk = (data) => {
  return data.tipologia_conferenza === "15" ? true : false;
};

export const whoTab = (data) => {
  return data?.relatori.find((i) => i.tipo_intervento !== "Ospite")
    ? true
    : false;
};

export const getURLImage = (data, relative = true) => {
  const immagine = data.immagini[0]?.url;
  if (immagine) {
    return relative ? immagine.replace(IMG_ADMIN, "") : immagine;
  } else {
    //TODO
  }
};

export const getSubType = (data, property = "label") => {
  if (!data?.idevento) return "";

  const genere = data.idevento["@_idgenere"];
  const tipoEvento = getTipoEvento(genere);
  try {
    switch (genere) {
      case ID_CONFERENZA:
        return data.tipologia_conferenza
          ? TIPO_CONFERENZA.find((i) => i.id === data.tipologia_conferenza)[
              property
            ]
          : tipoEvento;

      case ID_SPETTACOLO:
        return data.tipologia_spettacolo
          ? TIPO_SPETTACOLO.find((i) => i.id === data.tipologia_spettacolo)[
              property
            ]
          : tipoEvento;
      case ID_EVENTO_SPECIALE:
        return "Evento speciale";
      case ID_MOSTRA:
        return data.tipologia_mostra
          ? TIPO_MOSTRA.find((i) => i.id === data.tipologia_mostra)[property]
          : tipoEvento;
      case ID_LABORATORIO:
        return tipoEvento;
      case ID_EVENTO_OSPITE:
        return "Evento ospite";
      default:
        return "";
    }
  } catch (err) {
    console.error("err: ", err);
    return "";
  }
};

export const getAuthor = (data) => {
  try {
    const relatori = data.relatori;
    const genere = data["@_idgenere"];

    let author = "";
    if (relatori && data.tipologia_conferenza) {
      const conferenceType = TIPO_CONFERENZA.find(
        (i) => i.id === data.tipologia_conferenza
      ).label;
      author = conferenceType.concat(" con ");

      author = relatori
        .filter(
          (item) =>
            item.tipo_intervento !== "Introduttore" &&
            item.tipo_intervento !== "Moderatore"
        )
        .reduce((acc, value, index) => {
          if (index !== 0) acc += ", ";
          acc += value.nome + " " + value.cognome;
          return acc;
        }, author);

      author = relatori
        .filter((item) => item.tipo_intervento === "Moderatore")
        .reduce((acc, value, index) => {
          if (index === 0) {
            acc += ", modera ";
          } else {
            acc += ", ";
          }
          acc += value.nome + " " + value.cognome;
          return acc;
        }, author);

      author = relatori
        .filter((item) => item.tipo_intervento === "Introduttore")
        .reduce((acc, value, index) => {
          if (index === 0) {
            acc += "introduce ";
          } else {
            acc += ", ";
          }
          acc += value.nome + " " + value.cognome;
          return acc;
        }, author);
    } else if (
      (data.tipologia_spettacolo ||
        genere === ID_EVENTO_SPECIALE ||
        genere === ID_MOSTRA ||
        genere === ID_LABORATORIO) &&
      data.partecipanti
    ) {
      author += data.partecipanti;
    }
    return author;
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const decodeValue = (data, property) => {
  let curatori = "";
  if (data && data[property]) {
    const arr = data[property].split("#");
    const lastIndex = arr.length - 1;
    curatori = arr.reduce((acc, value, index) => {
      acc += value.split("&")[0];
      if (index !== lastIndex) acc += ", ";
      return acc;
    }, "");
  }
  return curatori;
};

export const getMarkerLocation = (data) => {
  return (
    data?.eventi_organizzazione?.luogo?.latitudine && {
      name: data.eventi_organizzazione.luogo.descrizione,
      location: {
        lat: Number(data.eventi_organizzazione.luogo.latitudine),
        lng: Number(data.eventi_organizzazione.luogo.longitudine),
      },
    }
  );
};

export const getErrorInfo = (code) => {
  if (code) return ERRORS.find((item) => item.code === code)?.message;
  else
    return {
      message: {
        text: "Si è verificato un errore",
        variant: "server-error",
      },
    };
};

export const getTicketOptions = (n) => {
  return new Array(n).fill(0).map((_, i) => ({ value: i + 1, label: i + 1 }));
};

export const getTicketDay = () => {
  const alldays = [...FESTIVAL_DAY.ottobre, ...FESTIVAL_DAY.novembre];
  const today = dayjs().format("YYYYMMDD");
  const options = alldays
    .filter((item) => item.daycode >= today)
    .map((day) => {
      return {
        value: day.searchcode,
        label: dayjs(day.daycode, "YYYYMMDD")
          .locale("it")
          .format("dddd, D MMMM"),
      };
    });
  return options;
};

export const isValidDay = (day) => {
  const alldays = [...FESTIVAL_DAY.ottobre, ...FESTIVAL_DAY.novembre];
  return alldays.some((item) => item.searchcode === day);
};

export const getTicketDesc = (day) => {
  const alldays = [...FESTIVAL_DAY.ottobre, ...FESTIVAL_DAY.novembre];
  console.log(day, alldays);
  const daycode = alldays.find((item) => item.searchcode === day).daycode;
  return getDayDesc(daycode);
};

export const getDayDesc = (daycode) => {
  return dayjs(daycode, "YYYYMMDD").locale("it").format("dddd, D MMMM");
};

export const getPlanetACode = () => {
  const today = dayjs().format("YYYYMMDD");
  const item = PLANET_A.find((item) => item.id === today);
  console.log(today, item);
  return item ? item.evplanet : PLANET_A[0].evplanet;
};

export const getPlanetBCode = () => {
  const today = dayjs().format("YYYYMMDD");
  const item = PLANET_B.find((item) => item.id === today);
  return item ? item.evplanet : PLANET_B[0].evplanet;
};

export const getLabelText = (label, key, defaultValue = "") => {
  return (
    label?.find((item) => item.key === key)?.value.replace("\\n", "\n") ||
    defaultLabels[key] ||
    defaultValue
  );
};
