import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import classNames from "classnames";
import Icon from "./icons";

const MenuItem = ({ item, index, selected = false }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(selected);

  return (
    <li
      itemProp="name"
      role="menuitem"
      className={classNames(
        "navigation__item",
        index === 0 && "navigation__item--first",
        subMenuOpen && "navigation__item--open",
        item?.pages &&
          "navigation__item--has-submenu d-md-flex align-items-md-center"
      )}
      key={item.name}
    >
      <NavLink
        itemProp="url"
        className="navigation__link"
        title={item.name}
        to={item.link}
      >
        {item.name}
      </NavLink>
      {item?.pages && (
        <button
          className="navigation__button"
          onClick={() => setSubMenuOpen(!subMenuOpen)}
        >
          <Icon name="angle-down" className="me-md-3 float-end float-md-none" />
        </button>
      )}
      {item?.pages && (
        <ul className="navigation__submenu">
          {item.pages.map((sitem, key) => (
            <li key={sitem.link}>
              <Link title={item.name} to={sitem.link}>
                {sitem.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
