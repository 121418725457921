import { useState, useEffect } from "react";
import Icon from "../components/icons";

const Accordion = ({ title, children, initialValue = false, rientro }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setOpen(initialValue);
    }
  }, [initialValue]);

  return (
    <div className="accordion">
      <h2 className="accordion__title">
        <button
          className="accordion__link"
          title={`Apri paragrafo ${title}`}
          onClick={() => setOpen(!open)}
        >
          <span className="accordion__label">{title}</span>
          <span className="accordion__line">&nbsp;</span>
          <Icon name={open ? "angle-up" : "angle-down"} />
        </button>
      </h2>
      {open && (
        <div className="accordion__text">
          <div className="row">
            <div
              className={`${
                rientro ? "col-12 offset-lg-3 col-lg-9" : "col-12"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
