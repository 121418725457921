const cognitoErrorMessages = {
  NotAuthorizedException: "Username o password errati",
  UsernameExistsException: "Email già registrata",
  // CodeMismatchException: 'Codice di conferma errato',
  // ExpiredCodeException: 'Codice di conferma scaduto',
  CodeMismatchException: "Link non valido o scaduto",
  ExpiredCodeException: "Link non valido o scaduto",
  InvalidPasswordException:
    "Formato password non corretto. La password deve contenere almeno una maiuscola, una minuscola, un numero, un simbolo e almeno 8 caratteri in totale",
  LimitExceededException: "Troppi tentativi, aspetta prima di riprovare",
};

export const getErrorMessage = (err) => {
  return (
    (err && err.code && cognitoErrorMessages[err.code]) ||
    "Si è verificato un errore"
  );
};
