import { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../components/header";
import LoginForm from "../components/login-form";
import UserContext from "../context/user";
import Breadcrumbs from "../components/breadcrumbs";
import { usePage } from "../hooks/useQueryCustom";
import PageContent from "../components/page-content";
import Seo from "../components/seo";
import { useNavigazione } from "../context/navigazione";
import { getLabelText } from "../utils/utility";

const AreaClientiWrapper = ({ page, breadcrumbs }) => {
  const { isLogged } = useContext(UserContext);
  const { getPathByTemplate } = useNavigazione();
  if (isLogged) {
    const bigliettiPrenotazioniLink = getPathByTemplate(
      "bigliettiPrenotazioni"
    );
    return <Navigate to={bigliettiPrenotazioniLink} />;
  }
  return isLogged === false ? (
    <AreaClienti page={page} breadcrumbs={breadcrumbs} />
  ) : null;
};

const AreaClienti = ({ page, breadcrumbs }) => {
  const { data } = usePage(page.link.slice(1));
  // console.log("AreaClienti page: ", data);

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper mt-3 mt-lg-5" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{data?.titoloInPagina || data?.titolo}</h1>
              <PageContent data={data} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="login-box">
                <div className="row">
                  <div className="col-md-8 offset-md-2 px-4 px-md-3">
                    <h2>Login</h2>
                    <p>{getLabelText(data?.label, "login")}</p>
                    <LoginForm />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <div className="login-box">
                <div className="row">
                  <div className="col-md-8 offset-md-2 px-4 px-md-3">
                    <h2>Registrati</h2>
                    <p>{getLabelText(data?.label, "registrati")}</p>
                    <div className="text-center mt-4">
                      <Link
                        to="/registrazione"
                        className="btn btn--link btn--small"
                        title="Registrati"
                      >
                        Registrati
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AreaClientiWrapper;
