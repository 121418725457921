import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import { useArticle } from "../hooks/useQueryCustom";
import Seo from "../components/seo";
import ContentfulRichText from "../components/contentful-rich-text";

const Articolo = () => {
  const { slug } = useParams();

  console.log("Page: ", slug);
  const { data } = useArticle(slug);
  const dataBreadcrumb = data ? [] : [];
  // TODO le pagine del menu potrebbero essere prese direttamente dal menu,
  // mentre per news e pagine non linkate andrebbe usato usePage o simile
  return (
    <>
      {data && <Seo title={data.titolo} />}
      <Header data={dataBreadcrumb} />
      <Breadcrumbs data={dataBreadcrumb} />
      <div role="main" className="main-wrapper mt-3 " id="main">
        <div className="container">
          {data && (
            <div className="row">
              <div
                className={`${
                  data?.rientroInPagina
                    ? "col-12 offset-md-2 col-md-8 "
                    : "col-12"
                }`}
              >
                <h1>{data.titolo}</h1>
                {data?.testo && <ContentfulRichText data={data.testo} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Articolo;
