import { useState, useCallback } from 'react';

const useTransitionAccordion = (time, startStatus = 'open') => {
  const [status, setStatus] = useState(startStatus);

  const toggle = useCallback(() => {
    if (status === 'open') {
      setStatus('transition');
      const timeout = setTimeout(() => setStatus('closed'), time);
      return () => clearTimeout(timeout);
    } else if (status === 'closed') {
      setStatus('transition');
      const timeout = setTimeout(() => setStatus('open'), 0);
      return () => clearTimeout(timeout);
    }
  }, [status, time]);

  return [status, toggle];
};

export default useTransitionAccordion;
