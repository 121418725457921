import { useState, useEffect, useRef, useCallback } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/header";
import { getErrorMessage } from "../utils/cognito-errors";
import { useNavigazione } from "../context/navigazione";

const ConfermaRegistrazione = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.get("code");
  const email = params.get("email");
  const { getPathByTemplate } = useNavigazione();
  const bigliettiPrenotazioniLink = getPathByTemplate("bigliettiPrenotazioni");

  const confirmSignUp = useCallback(
    async (email, code) => {
      setLoading(true);
      try {
        await Auth.confirmSignUp(email, code, {
          clientMetadata: { from: "www.festivalscienza.it" },
        });
        console.log("Confirmed sign up");
        setConfirmed(true);
      } catch (err) {
        console.log("error confirming sign up:", err);
        if (
          err.code === "CodeMismatchException" ||
          err.code === "ExpiredCodeException"
        ) {
          setError(getErrorMessage(err));
          setInvalid(true);
        } else if (err.code === "NotAuthorizedException") {
          console.warn("Utente già confermato");
          navigate(bigliettiPrenotazioniLink, { state: { email } });
        } else {
          setError(getErrorMessage(err));
        }
      }
      setLoading(false);
    },
    [navigate, bigliettiPrenotazioniLink]
  );

  const resendCode = useCallback(async () => {
    setLoadingCode(true);
    try {
      await Auth.resendSignUp(email, { from: "www.festivalscienza.it" });
      console.log("code resent successfully");
      setError("");
      setInvalid(false);
    } catch (err) {
      console.log("error resending link:", err);
      setError(getErrorMessage(err));
    }
    setLoadingCode(false);
  }, [email]);

  const sentRef = useRef();

  useEffect(() => {
    if (email && code) {
      if (sentRef.current !== email + code) {
        sentRef.current = email + code;
        confirmSignUp(email, code);
      }
    } else {
      navigate(bigliettiPrenotazioniLink);
    }
  }, [code, email, navigate, confirmSignUp, bigliettiPrenotazioniLink]);

  return (
    <>
      <Header />
      <div role="main" className="main-wrapper mt-3 mt-lg-5" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Conferma registrazione</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
              <div className="login-box">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {error && !loading && !loadingCode ? (
                      <div className="form__err-message my-3">
                        <p>{error}</p>
                      </div>
                    ) : null}
                    <label className="form__label">
                      Email
                      <input
                        type="text"
                        name="email"
                        value={email}
                        readOnly={true}
                        className="form__input"
                      />
                    </label>
                    {loading ? (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Caricamento...
                      </div>
                    ) : null}
                    {invalid ? (
                      <div className="d-flex justify-content-end my-3">
                        <button
                          className="btn btn--link btn--small"
                          onClick={resendCode}
                        >
                          {loadingCode ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Caricamento...
                            </>
                          ) : (
                            "Reinvia"
                          )}
                        </button>
                      </div>
                    ) : null}
                    {confirmed ? (
                      <div className="mt-3">
                        <p>
                          Registrazione confermata. Ora puoi fare la{" "}
                          <Link
                            to={bigliettiPrenotazioniLink}
                            state={{ email }}
                          >
                            LOGIN
                          </Link>{" "}
                          al sito.
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfermaRegistrazione;
