import { useMemo } from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Steps from "../components/steps";
import { isValidDay, getTicketDesc, getDayDesc } from "../utils/utility";
import { useListaAcquisti, useRemoveBooking } from "../hooks/useQueryCart";
import Icon from "../components/icons";
import { useNavigazione } from "../context/navigazione";

const Ordini = () => {
  const { getPathByTemplate } = useNavigazione();
  const bigliettiPrenotazioniLink = getPathByTemplate("bigliettiPrenotazioni");
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Biglietti e prenotazioni",
        link: bigliettiPrenotazioniLink,
      },
      {
        label: "Completa l'acquista",
      },
    ];
  }, [bigliettiPrenotazioniLink]);
  const { data: acquisti, isLoading } = useListaAcquisti();
  const removeBooking = useRemoveBooking();

  const onRemoveFriendBooking = async (idSlot) => {
    try {
      removeBooking.mutate({ id_slot: idSlot });
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  return (
    <>
      <Header data={breadcrumbs} />
      <Breadcrumbs data={breadcrumbs} />
      <div role="main" className="main-wrapper" id="main">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h1>I tuoi ordini</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Steps selected={4} />
            </div>
          </div>
          {isLoading && (
            <div className="row">
              <div className="col-12">
                <div className="text-center my-5">
                  <Icon name="spinner" className="icon--big" />
                </div>
              </div>
            </div>
          )}
          {acquisti && (
            <div className="bg-gray px-4 py-5 mb-4">
              <div className="row">
                <div className="col-12">
                  <h2 className="h3 graphic">
                    <span className="graphic__label graphic__label--gray-light">
                      {acquisti?.id_amico ? "Le tue prenotazioni" : "Acquisti"}
                    </span>
                    <span className="graphic__line">&nbsp;</span>
                  </h2>
                </div>
                {acquisti?.id_amico ? (
                  <>
                    {acquisti.book.map((book) => (
                      <div key={book.id_book} className="row">
                        <div className="col-12 col-md-4">{book.titolo}</div>
                        <div className="col-12 col-md-4">
                          {getDayDesc(book.data_book)} {book.orario_book}
                        </div>
                        <div className="col-12 col-md-4">
                          {" "}
                          <button
                            className="btn"
                            onClick={(e) => onRemoveFriendBooking(book.id_slot)}
                            type="button"
                          >
                            Rimuovi
                          </button>
                        </div>
                      </div>
                    ))}
                    {acquisti.book.length > 0 ? (
                      <div className="row mt-2">
                        <div className="col-12">
                          <a className="no-blank" href={acquisti.pdf}>
                            <strong>Scarica l'ordine in pdf</strong>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div>Nessuna prenotazione presente</div>
                    )}
                  </>
                ) : (
                  <>
                    {acquisti?.map((item) => (
                      <div key={item.id_carrello} className="row mb-5">
                        <div className="col-lg-10 offset-lg-2">
                          <div className="row d-none d-sm-flex mb-2">
                            <div className="col-4">
                              <strong>Acquisti</strong>
                            </div>
                            <div className="col-4">
                              <strong>Periodo</strong>
                            </div>
                            <div className="col-4">
                              <strong>Quantità</strong>
                            </div>
                          </div>
                          <div className="row">
                            {item.tck === "abb" && (
                              <>
                                <div className="col-12 col-md-4">
                                  Abbonamento
                                </div>
                                <div className="col-12 col-md-4">
                                  Tutte le giornate del Festival
                                </div>
                              </>
                            )}

                            {item.tck === "premium" && (
                              <>
                                <div className="col-12 col-md-4">
                                  Abbonamento premium
                                </div>
                                <div className="col-12 col-md-4">
                                  Tutte le giornate del Festival
                                </div>
                              </>
                            )}
                            {isValidDay(item.tck) && (
                              <>
                                <div className="col-12 col-md-4">
                                  Giornaliero
                                </div>
                                <div className="col-12 col-md-4">
                                  {getTicketDesc(item.tck)}
                                </div>
                              </>
                            )}
                            <div className="col-4">
                              {item.num &&
                                `Adulti ${item.num} ${
                                  item.num_raga ? " - " : ""
                                }`}
                              {item.num_raga && `Ragazzi ${item.num_raga}`}
                            </div>
                          </div>
                          {item.book.map((book) => (
                            <div key={book.id_book} className="row">
                              <div className="col-12 col-md-4">
                                {book.titolo}
                              </div>
                              <div className="col-12 col-md-4">
                                {getDayDesc(book.data_book)} {book.orario_book}
                              </div>
                              <div className="col-12 col-md-4">
                                {book.num_book}
                              </div>
                            </div>
                          ))}
                          <div className="row mt-2">
                            <div className="col-12">
                              <a className="no-blank" href={item.pdf}>
                                <strong>Scarica l'ordine in pdf</strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Ordini;
