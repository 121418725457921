import { useEffect } from "react";

const useBodyClass = (className, show = true) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add(className);
      return () => document.body.classList.remove(className);
    }
  }, [className, show]);
};

export default useBodyClass;
