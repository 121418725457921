import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import PageContent from "../components/page-content";
import { usePage } from "../hooks/useQueryCustom";
import Seo from "../components/seo";

const Newsletter = ({ page, breadcrumbs }) => {
  const { data } = usePage(page.link.slice(1));
  // console.log("Page: ", data);
  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper mt-3 mt-lg-5" id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{page.name}</h1>
              <div className="form form--embedded">
                <PageContent data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newsletter;
