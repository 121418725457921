import classNames from "classnames";
import { useFasciaPersone } from "../hooks/useQueryCustom";
import plus from "../assets/plus@2x.png";
import ConditionalWrapper from "../utils/conditional-wrapper";
import { Link } from "react-router-dom";
import Accordion from "./accordion";
import Modal from "react-overlays/Modal";
import { useCallback, useState } from "react";
import Icon from "./icons";

const ElencoPersone = ({ persone }) => {
  const [showModal, setShowModal] = useState(false);
  const [persona, setPersona] = useState(null);
  const closeModal = useCallback(() => setShowModal(false), []);

  const openModal = (item) => {
    setShowModal(true);
    setPersona(item);
  };
  return (
    <div className="row">
      {persone?.map((item, index) =>
        item?.foto ? (
          <div
            key={index}
            className="col-6 col-md-4 col-lg-3 col-xl-2 my-4 text-center"
          >
            <ConditionalWrapper
              condition={item.bio}
              wrapper={(children) => (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(item);
                  }}
                  to="/"
                >
                  {children}
                </Link>
              )}
            >
              <>
                <figure
                  className={classNames(
                    "persone__foto",
                    item.bio && "persone__foto--link"
                  )}
                >
                  <img src={item?.foto?.url} alt={item.titolo} />
                  <figcaption
                    className={classNames(
                      "persone__plus",
                      item.bio ? "d-block" : "d-none"
                    )}
                  >
                    <img src={plus} alt="leggi di più" />
                  </figcaption>
                </figure>

                <h3 className="persone__nome">
                  {item.nome} {item.cognome}
                </h3>
              </>
            </ConditionalWrapper>
            <span className="persone__ruolo">{item.ruolo}</span>
          </div>
        ) : null
      )}
      {persona && (
        <Modal
          show={showModal}
          onHide={closeModal}
          className="modal__internal d-flex flex-column"
          aria-labelledby="modal-label"
          title={`${persona.nome} ${persona.cognome}`}
        >
          <>
            <button
              aria-label="close"
              className="modal__close"
              onClick={closeModal}
            >
              <Icon className="ms-2" name="xmark" />
            </button>
            <div className="modal__header">
              <h3>
                {persona.nome} {persona.cognome}
              </h3>
            </div>
            <div
              className="modal__body"
              dangerouslySetInnerHTML={{
                __html: persona.bio,
              }}
            ></div>
          </>
        </Modal>
      )}
    </div>
  );
};

const FasciaPersone = ({ id }) => {
  const { data } = useFasciaPersone(id);
  const titoloSezione = data?.titoloSezione || "Chi siamo";
  const sfondoGrigio = data?.sfondoGrigio;
  const layout = data?.layout;
  const persone = data?.personeCollection?.items;
  console.log(data);
  return (
    <>
      {layout === "Accordion" ? (
        <Accordion title={titoloSezione}>
          <ElencoPersone persone={persone} />
        </Accordion>
      ) : (
        <section
          className={classNames(
            "persone mb-3 py-3 py-md-5",
            data?.sfondoGrigio && "bg-gray"
          )}
        >
          <div className={sfondoGrigio ? "container-md" : ""}>
            <div className="row">
              <div className="col-sm-12">
                <h2 className="graphic">
                  <span
                    className={classNames(
                      "graphic__label",
                      sfondoGrigio && "graphic__label--evidenza"
                    )}
                  >
                    {titoloSezione || "Chi siamo"}
                  </span>
                  <span className="graphic__line">&nbsp;</span>
                </h2>
              </div>
            </div>

            <ElencoPersone persone={persone} />
          </div>
        </section>
      )}
    </>
  );
};

export default FasciaPersone;
