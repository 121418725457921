import { useForm } from "react-hook-form";
import Modal from "react-overlays/Modal";
import { Auth } from "@aws-amplify/auth";
import Icon from "./icons";
import { getErrorMessage } from "../utils/cognito-errors";

const renderBackdrop = () => <div className="modal__external"></div>;

const ForgotPasswordModal = ({ show, onHide, email: formEmail }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: { email: formEmail, email2: "" },
  });

  const onSubmit = async (values) => {
    if (!values.email) {
      console.warn("Email necessaria");
      return;
    }
    try {
      const data = await Auth.forgotPassword(values.email, {
        from: "www.festivalscienza.it",
      });
      console.log("forgotPassword: ", data);
      clearErrors("server");
    } catch (err) {
      console.error("Error in forgotPassword: ", err);
      setError("server", { message: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="modal__internal d-flex flex-column"
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <>
        <button aria-label="close" className="modal__close" onClick={onHide}>
          <Icon className="ms-2" name="xmark" />
        </button>
        <div className="modal__header">
          <h3>Password dimenticata</h3>
        </div>

        <p>
          Inserisci l'indirizzo email di registrazione. Se risulterà registrato,
          ti invieremo un link per poter cambiare la password.
        </p>
        <div className="my-3 modal__body">
          <div className="login-box">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                {isSubmitSuccessful ? (
                  <div>
                    Ti abbiamo inviato una email con il link per cambiare la
                    password.
                    <br />
                    Se non la trovi, controlla nello spam.
                  </div>
                ) : (
                  <form
                    className="form"
                    name="forgot-password"
                    onSubmit={(e) => {
                      clearErrors("server");
                      handleSubmit(onSubmit)(e);
                    }}
                  >
                    <label className="form__label">
                      E-mail
                      <input
                        type="email"
                        className="form__input me-2 p-1"
                        {...register("email", {
                          required: "Campo obbligatorio",
                        })}
                      />
                    </label>
                    {errors.email && <span>{errors.email.message}</span>}
                    <label className="form__label">
                      Ripeti E-mail
                      <input
                        type="email"
                        className="form__input me-2 p-1"
                        {...register("email2", {
                          required: "Campo obbligatorio",
                          validate: (value, values) =>
                            value === values.email || "Le email non coincidono",
                        })}
                      />
                    </label>
                    {errors.email2 && <span>{errors.email2.message}</span>}
                    <div className="mt-4 text-center">
                      <button
                        className="btn btn--link btn--small"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div className="d-flex align-items-center">
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Caricamento...
                          </div>
                        ) : (
                          "Invia"
                        )}
                      </button>
                    </div>
                    {errors.server && <span>{errors.server.message}</span>}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ForgotPasswordModal;
