import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UserContext from "../context/user";
import {
  URL_PDF_ACQUISTO,
  URL_PDF_SERVICE,
  URL_PDF_AMICO,
} from "../utils/constants";

export const useCart = () => {
  const { userProfile, callApi } = useContext(UserContext);
  return useQuery({
    queryKey: ["cart", userProfile?.username],
    queryFn: () => callApi(`/api/ecommerce/cart`),
    select: (data) => {
      const adulti = Number(data.num) || 0;
      const ragazzi = Number(data.num_raga) || 0;
      data.partecipanti = new Array(adulti)
        .fill({ type: "intero" })
        .concat(new Array(ragazzi).fill({ type: "ridotto" }));

      return data;
    },
    enabled: !!userProfile,
  });
};

export const useListaAcquisti = () => {
  const { userProfile, callApi, isAmico } = useContext(UserContext);
  return useQuery({
    queryKey: ["ordini", userProfile?.username],
    queryFn: () => callApi(`api/ecommerce/lista-acquisti`),
    select: (data) => {
      if (isAmico && data.data?.id_amico) {
        console.log("data amico", data.data);
        const url = `${URL_PDF_AMICO}?id=${data.data.id_amico}`;
        const pdf = `${URL_PDF_SERVICE}?url=${encodeURIComponent(url)}`;
        data.data.pdf = pdf;
        return data.data;
      } else {
        const pagati = data?.data?.acquisti.filter(
          (item) => item.stato === "pagato"
        );
        return pagati.map((obj) => {
          const url = `${URL_PDF_ACQUISTO}?id=${obj.id_carrello}&code=${obj.acquisto_pin}&cli=${userProfile.username}`;
          const pdf = `${URL_PDF_SERVICE}?url=${encodeURIComponent(url)}`;
          return { ...obj, pdf };
        });
      }
    },
    enabled: !!userProfile,
  });
};

export const useAddTicket = () => {
  const queryClient = useQueryClient();
  const { callApi, userProfile } = useContext(UserContext);
  const addTicket = useMutation(
    (data) => {
      return callApi("/api/ecommerce/add-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["cart", userProfile?.username], data.data);
      },
    }
  );
  return addTicket;
};

export const useRemoveTicket = () => {
  const queryClient = useQueryClient();
  const { callApi, userProfile } = useContext(UserContext);
  const addTicket = useMutation(
    () => {
      return callApi("/api/ecommerce/remove-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["cart", userProfile?.username], data.data);
      },
    }
  );
  return addTicket;
};

export const usePremium = () => {
  const queryClient = useQueryClient();
  const { callApi, userProfile } = useContext(UserContext);
  const addPremium = useMutation(
    (data) => {
      return callApi("/api/ecommerce/attiva-premium", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["cart", userProfile?.username], data.data);
      },
    }
  );
  return addPremium;
};

export const useAddBooking = () => {
  const queryClient = useQueryClient();
  const { callApi, userProfile } = useContext(UserContext);
  const addBooking = useMutation(
    (data) => {
      return callApi("/api/ecommerce/add-booking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["cart", userProfile?.username], data.data);
      },
    }
  );
  return addBooking;
};

export const useRemoveBooking = () => {
  const queryClient = useQueryClient();
  const { callApi, userProfile } = useContext(UserContext);
  const addTicket = useMutation(
    (data) => {
      return callApi("/api/ecommerce/remove-booking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["cart", userProfile?.username], data.data);
        queryClient.invalidateQueries({
          queryKey: ["ordini"],
        }); //serve per gli amici
      },
    }
  );
  return addTicket;
};
