import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Seo from "../components/seo";
import PageContent from "../components/page-content";
import { usePage } from "../hooks/useQueryCustom";
import usebodyClass from "../hooks/useBodyClass";

const ScriviAlFestival = ({ page, breadcrumbs }) => {
  const { data } = usePage(page.link.slice(1));
  usebodyClass("show-captcha");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();

  const onSubmit = async (values) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("scriviAlFestival");
    try {
      const response = await fetch("/api/form/contact", {
        method: "POST",
        body: JSON.stringify({
          name: values.name,
          surname: values.surname,
          email: values.email,
          message: values.message,
          captcha: token,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error form ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      console.log("Form submitted: ", data);
    } catch (err) {
      console.error("Error: ", err);
      setError("server", { message: "Si è verificato un errore" });
    }
  };

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      <Breadcrumbs data={breadcrumbs} />
      <div role="main" className="main-wrapper mt-3 mt-lg-5" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <h1>{page.name}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2">
              <PageContent data={data} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              {isSubmitSuccessful ? (
                <div className="cart bg-gray px-4 py-5 mb-4">
                  <div className="text-center">
                    Grazie per averci contattato
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-end">*Campi obbligatori</div>
                  <div className="login-box">
                    <div className="row">
                      <div className="col-md-10 offset-md-1 px-4 px-md-3">
                        <form
                          className="form"
                          onSubmit={(e) => {
                            clearErrors("server");
                            handleSubmit(onSubmit)(e);
                          }}
                        >
                          <label className="form__label">
                            Nome
                            <input
                              className="form__input"
                              {...register("name")}
                            />
                          </label>
                          {errors.name && <span>{errors.name.message}</span>}
                          <label className="form__label">
                            Cognome
                            <input
                              className="form__input"
                              {...register("surname")}
                            />
                          </label>
                          {errors.surname && (
                            <span>{errors.surname.message}</span>
                          )}
                          <label className="form__label">
                            Email *
                            <input
                              type="email"
                              className="form__input"
                              {...register("email")}
                            />
                          </label>
                          {errors.email && <span>{errors.email.message}</span>}
                          <label className="form__label">
                            Messaggio *
                            <textarea
                              className="form__input"
                              {...register("message", {
                                required: "Campo obbligatorio",
                              })}
                            />
                          </label>
                          {errors.message && (
                            <span>{errors.message.message}</span>
                          )}
                          <button
                            className="btn btn--link btn--small align-self-start mt-4"
                            title="Registrati"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}{" "}
                            Invia
                          </button>
                          {errors.server && (
                            <span>{errors.server.message}</span>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScriviAlFestival;
