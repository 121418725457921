import classNames from "classnames";

const Steps = ({ selected }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="steps d-flex justify-content-between">
          <div
            className={classNames(
              "steps__step  steps__step--first",
              selected > 0 && "steps__step--current"
            )}
          >
            <span className="steps__text">Scegli</span>
            <span className="steps__ico">&nbsp;</span>
            <span className="steps__line">&nbsp;</span>
          </div>
          <div
            className={classNames(
              "steps__step",
              selected > 1 && "steps__step--current"
            )}
          >
            <span className="steps__text">Carrello</span>
            <span className="steps__ico">&nbsp;</span>
            <span className="steps__line">&nbsp;</span>
          </div>
          <div
            className={classNames(
              "steps__step",
              selected > 2 && "steps__step--current"
            )}
          >
            <span className="steps__text">
              Paga <span className="d-none d-md-inline">online</span>
            </span>
            <span className="steps__ico">&nbsp;</span>
            <span className="steps__line">&nbsp;</span>
          </div>
          <div
            className={classNames(
              "steps__step steps__step--last",
              selected > 3 && "steps__step--current"
            )}
          >
            <span className="steps__text">
              Riepilogo <span className="d-none d-md-inline">acquisti</span>
            </span>
            <span className="steps__ico">&nbsp;</span>
          </div>
          <div className="clear">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
